'use strict';

const googleMapAPiKey = 'AIzaSyBnTa3dLGKB-Qc7isrqN2O4xoqjw5-SuL0';
let ACG = ACG || {};
let UTILS = UTILS || {};

ACG.utils = UTILS;

ACG.position = $(window).scrollTop();
ACG.utils.navbarPosition = function navbarPosition() {
    var scroll = window.scrollY  || window.pageYOffset  || document.documentElement.scrollTop;

    if (document.querySelector('.js-navbar-compact-scroll')) {
        if (scroll > 200) {
            if (scroll > ACG.position) {
                document.querySelector('.js-navbar-compact-scroll').classList.add('l-navbar--hidden');
            } else {
                document.querySelector('.js-navbar-compact-scroll').classList.remove('l-navbar--hidden');
            }
        } else {
            document.querySelector('.js-navbar-compact-scroll').classList.remove('l-navbar--hidden');
        }

        ACG.position = scroll;

    } else if (document.querySelector('.js-navbar-compact-reverse-scroll')) {

        if (scroll > 50) {
            document.querySelector('.js-navbar-compact-reverse-scroll').classList.remove('l-navbar--hidden');

        } else {
            document.querySelector('.js-navbar-compact-reverse-scroll').classList.add('l-navbar--hidden');
        }

        ACG.position = scroll;
    }
};

ACG.utils.selectChange = function selectChange() {
    if ( document.querySelector('.js-select-box') ) {
        var formSelects = document.querySelectorAll('.js-select-box');

        [].forEach.call(formSelects, function(formSelect) {
            formSelect.addEventListener("change", function( event ) {
                event.preventDefault();
                if (event.target.value) {
                    window.location.href = event.target.value;
                }
            }, false);
        });
    }
};

ACG.utils.inputLabels = function inputLabels() {
    function placeholderPolyfill(el) {
      this.classList[this.value ? 'add' : 'remove']('filled');
    }

    if ( document.querySelector('input') ) {
        let allInputs = document.querySelectorAll('input');

        [].forEach.call(allInputs, function(singleInput) {
            singleInput.classList[singleInput.value ? 'add' : 'remove']('filled');
            singleInput.addEventListener('change', placeholderPolyfill);
            singleInput.addEventListener('keyup', placeholderPolyfill);
        });
    }

    if ( document.querySelector('textarea') ) {
        let allTextareas = document.querySelectorAll('textarea');

        [].forEach.call(allTextareas, function(singleTextarea) {
            singleTextarea.classList[singleTextarea.value ? 'add' : 'remove']('filled');
            singleTextarea.addEventListener('change', placeholderPolyfill);
            singleTextarea.addEventListener('keyup', placeholderPolyfill);
        });
    }
};

ACG.utils.burger = function burger() {
    if ( document.querySelector('.js-burger') ) {
        var burgerButton = document.querySelector('.js-burger'),
            navWrapper = document.querySelector('.l-navbar__links'),
            body = document.querySelector('body');

        burgerButton.addEventListener("click", function( event ) {
            event.preventDefault();
            burgerButton.classList.contains('burger--open') ? burgerButton.classList.remove('burger--open') : burgerButton.classList.add('burger--open');
            burgerButton.classList.contains('burger--open') ? navWrapper.classList.add('l-navbar__links--visible') : navWrapper.classList.remove('l-navbar__links--visible');
            burgerButton.classList.contains('burger--open') ? body.classList.add('stop-scrolling') : body.classList.remove('stop-scrolling');
        }, false);
    }
};

ACG.utils.customHashtagActions = function customHashtagActions() {
    $('a[href*="#scrollToContactForm"]').on('click', function() {
        const href = $(this).attr('href');
        const prefix = '#scrollToContactForm['.length;
        const suffix = href.length;
        const subjectTitle = href.substring(prefix, suffix-1) || '';
        UTILS.scrollToID('#contact-form');

        $('#contact-form #subject').val(subjectTitle).addClass('filled');
        return false;
    });
};

ACG.contact = {
    loadScript: function() {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://maps.googleapis.com/maps/api/js?v=3&key=' + googleMapAPiKey + '&' +
                'callback=ACG.contact.map';
        document.body.appendChild(script);
    },

    map: function() {
        function init() {
                var draggable;
                var isMobile = function() {
                try{ document.createEvent("TouchEvent"); return true; }
                    catch(e){ return false; }
                };
                if(isMobile()) {
                    draggable = false;
                } else {
                    draggable = true;
                }

                var maps = document.querySelectorAll('.js-map');

                [].forEach.call(maps, function(mapObj) {

                    var myLatLngX = mapObj.getAttribute('data-latitude');
                    var myLatLngY = mapObj.getAttribute('data-longitude');

                    var mapOptions = {
                            zoom: 15,
                            center: new google.maps.LatLng(myLatLngX,myLatLngY),
                            scrollwheel: false,
                            draggable: draggable,
                            styles: [
                                {
                                    "featureType": "administrative",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                        {
                                            "color": "#444444"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "landscape",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "color": "#f2f2f2"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "poi",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "saturation": -100
                                        },
                                        {
                                            "lightness": 45
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "visibility": "simplified"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.arterial",
                                    "elementType": "labels.icon",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "transit",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "water",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "color": "#46bcec"
                                        },
                                        {
                                            "visibility": "on"
                                        }
                                    ]
                                }
                            ],
                            zoomControl: true,
                            zoomControlOptions: {
                                style: google.maps.ZoomControlStyle.LARGE,
                                position: google.maps.ControlPosition.RIGHT_TOP
                            }
                    };

                    var mapElement = mapObj;
                    var map = new google.maps.Map(mapElement, mapOptions);
                    var image = '/images/map-pin.png';
                    var marker = new google.maps.Marker({
                        position: map.getCenter(),
                        icon: image,
                        map: map,
                        animation: google.maps.Animation.DROP,
                        url: 'http://maps.google.com/?q='+myLatLngX+','+myLatLngY
                    });

                    google.maps.event.addDomListener(window, 'resize', function() {
                        var center = map.getCenter();
                        google.maps.event.trigger(map, 'resize');
                        map.setCenter(center);
                    });
                });
        }

        init();
    }
};

ACG.form = {
    sendMessage (form, apiUri) {
        const initialSubmitButtonText = $(form).find('button[type="submit"]').text();
        let data = $(form).serialize(),
            loading = true,
            url = apiUri || '/ajax/sendMessage';

        $(form).find('button[type="submit"]').text('Sending...');

        $.ajax({
            url: url,
            method: 'POST',
            data: data,
            dataType: 'json'
        })
        .then(function(response) {
            $(form).find('button[type="submit"]').text(initialSubmitButtonText);

            if (response.url) {
                window.location.href = response.url;
            } else {
                if ( $(form).find('.form__response--ok').length === 0 ) {
                    $(form).prepend( '<div class="form__response form__response--ok"><p>'+response.message+'</p></div>' );
                } else {
                    $('.form__response--ok p').html(response.message);
                }
            }

            // remove error response if exists
            $(form).find('.form__response--error').remove();
            $(form)[0].reset();

        }, function(response) {
            let errorMessage = '';

            if (response.status == 422) {
                for (var field in response.responseJSON) {
                    if (response.responseJSON.hasOwnProperty(field)) {
                        errorMessage += response.responseJSON[ field ].join('<br>') + '<br>';
                    }
                }
            } else if (response.responseJSON.message) {
                errorMessage = response.responseJSON.message;
            }

            if (errorMessage) {
                if ( $(form).find('.form__response--error').length === 0 ) {
                    $(form).prepend( '<div class="form__response form__response--error"><p>'+errorMessage+'</p></div>' );
                } else {
                    $('.form__response--error p').html(errorMessage);
                }
            }

            // remove success response if exists
            $(form).find('.form__response--ok').remove();

            $(form).find('button[type="submit"]').text('Submit');
        });

        return false;
    }
};

ACG.utils.hashAnchorClick();
ACG.utils._animationedSections();
ACG.utils._openPopupOnClick();
ACG.utils._magnific();

if (document.querySelector('.js-map')) {
    ACG.contact.loadScript();
}

ACG.utils.inputLabels();

window.onload = function() {
    ACG.utils.navbarPosition();
    ACG.utils.selectChange();
    ACG.utils.burger();
    ACG.utils.infiniteScroll();
    Waypoint.refreshAll();
    ACG.utils.customHashtagActions();

    ACG.utils.inputLabels();
};

window.addEventListener('scroll', function() {
    ACG.utils.navbarPosition();
}, false);
